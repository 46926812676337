import http from "../utils/http"

/**
 * รายละเอียดการชําระเงิน
 * @param {object} params
 */
export function getPayInfo(params) {
    return http({
        url: "/api/pay/info",
        data: params,
        forceLogin: true
    })
}

/**
 * วิธีการชําระเงิน
 * @param {object} params
 */
export function getPayType(params) {
    return http({
        url: "/api/pay/type",
        data: params,
        forceLogin: true
    })
}

/**
 * สถานะการชําระเงิน
 * @param {object} params
 */
export function checkPayStatus(params) {
    return http({
        url: "/api/pay/status",
        data: params,
        forceLogin: true
    })
}

/**
 * สถานะการชําระเงิน
 * @param {object} params
 */
export function pay(params) {
    return http({
        url: "/api/pay/pay",
        data: params,
        forceLogin: true
    })
}
